import React from 'react'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import Image from 'components/image/image'
import './superhuman-apps-section.sass';

const SuperhumanAppsSection = ({data}) => {
   return (
        <div className="SuperhumanAppsSection">
            <div className="container-fluid">
                {data.map((item, i) =>
                    <div key={i} className="SuperhumanAppsSection__item">
                        <div className="SuperhumanAppsSection__item__header">
                            <Image image_url={item.icon}
                                className="SuperhumanAppsSection__item__icon mb-4"
                                alt="section-icon" />
                            <h2>{item.title}</h2>
                        </div>
                        <div className="SuperhumanAppsSection__item__list" dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                )}
            </div>
        </div>
   )
}

export default withSectionTracking(React.memo(SuperhumanAppsSection))