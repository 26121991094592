import React from 'react'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import Image from 'components/image/image'
import './app-features.sass'

const AppFeatures = ({data}) => {
   return (
        <div className="AppFeatures">
            <div className="container-fluid">
                {data.map((row, i) =>
                    <div key={i} className="AppFeatures__row">
                        <div className="AppFeatures__row__content">
                            <div className="AppFeatures__row__content__inner">
                                <h2>{row.title}</h2>
                                {row.subtitle ?
                                    <p>{row.subtitle}</p>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="AppFeatures__row__img">
                            <Image image_url={row.image}
                                alt={row.title} />
                        </div>
                    </div>
                )}
            </div>
        </div>
   )
}

export default withSectionTracking(React.memo(AppFeatures))