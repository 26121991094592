import React from 'react'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import Image from 'components/image/image'
import './platform-uses.sass'

const PlatformUses = ({ data }) => (
    <div className="PlatformUses container-fluid mb-5">
        <h2 className="mb-3">{data.title}</h2>
        <p>{data.subtitle}</p>
        <p>{data.imgTitle}</p>
        <div className="PlatformUses__images--desktop mb-5">
            <div><Image image_url="content/platform-uses/platform-uses.svg"/></div>
        </div>
        <div className="PlatformUses__images--mobile mb-5">
            <div><Image image_url="content/platform-uses/platform-uses_mobile.svg"/></div>
        </div>
        <h2 className="mb-3">{data.whoTitle}</h2>
        <p>{data.whoSubtitle}</p>
    </div>
)

export default withSectionTracking(React.memo(PlatformUses))
