import React from 'react'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import ClientListLogos from 'components/client-list-logos/client-list-logos'
import Image from 'components/image/image'
import ScrollButton from 'components/scroll-button/scroll-button'
import PlatformVideo from 'components/platform-video/platform-video'
import './hero.sass'

const IndexHero = ({ data, clientLogos}) => {
  return (
    <div className="IndexHero">
    <Image image_url="layout/bg-hexagon-stripes.svg"
           className="IndexHero__background--stripes"
           alt="Hero section background of hexagon stripes shape" />

      <div className="IndexHero__container container-fluid d-flex justify-content-between">
        <Image image_url="layout/bg-hexagon-filled.svg"
              className="IndexHero__background"
              alt="Hero section background of hexagon filled shape" />

        <div className="IndexHero__call-to-action">
          <h1 className="h2--new">{data.title}<br />{data.titleBr}</h1>
          <h2 className="IndexHero__subtitle">{data.subtitle}</h2>
          <div className="position-relative">
            <ScrollButton
              to="#k-anchor__get-in-touch"
              id="hero__get-in-touch"
              className="btn">
              Free Trial
            </ScrollButton>
          </div>
        </div>

        <div className="IndexHero__video">
          <PlatformVideo/>
          <p>To see a full 30 min raw data to live Machine Learning app build, click <a href="https://www.youtube.com/watch?v=hPBMvCO9YN4">here</a>.</p>
        </div>
      </div>
      <ClientListLogos data={clientLogos} sectionId="Home-page2__client-logos" />
    </div>
  )
}

export default withSectionTracking(React.memo(IndexHero))
