import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import Image from 'components/image/image'
import './client-list-logos.sass'

const ClientLogosMobile = (data) => {
  return data.map((client, i) => (
      <div className="Client__box Client__mobile d-flex flex-column justify-content-center text-center k-px-container" key={i}>
        <Image key={i}
              image_url={`clients/${client.logo}`}
              className="k-margin-0-auto mb-3"
              alt={`${client.name} client logo`} />
      </div>
    )
  )
}

const ClientLogosDesktop = ({data}) => {
  return data.map((client, i) => (
      <div className="ClientLogos__desktop" key={i}>
          <div className="ClientLogos__box text-center">
              <div className="ClientLogos__desktop__logo">
                <img
                  key={i}
                  src={require(`images/clients/${client.logo}`).default}
                  alt={`${client.name} client logo`}/>
              </div>
          </div>
      </div>
  ))
}

const ClientListLogos = ({data}) => (
  <div className="ClientListLogos">
    <div className="ClientLogos container-fluid">
      <div className="ClientLogos__desktop__container">
        <ClientLogosDesktop data={data} />
      </div>
      <div className="ClientLogos__mobile__container mt-5">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          emulateTouch={true}
          swipeScrollTolerance={50}
          transitionTime={150}>
            {ClientLogosMobile(data)}
        </Carousel>
      </div>
    </div>
  </div>
)

export default withSectionTracking(React.memo(ClientListLogos))
