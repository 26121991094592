import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { UPDATE_PLATFORM_REGISTRATION } from 'store/actions'
import Banner from "components/banner/banner";
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import IndexHero from 'components/hero/hero'
import PlatformUses from 'components/platform-uses/platform-uses'
import ClientList from 'components/client-list/client-list'
import SalesSection from 'components/sales-section/sales-section'
import SuperhumanAppsSection from 'components/superhuman-apps-section/superhuman-apps-section'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import AppFeatures from 'components/app-features/app-features'


const IndexPage = ({data, updatePlatformRegistration}) => (
  <>
    <Banner/>
    <Layout bodyClass="home-page">
        <SEO
            title="Kortical | AI Platform - AutoML, ML Ops, AI as a Service & AI Consultancy"
            keywords={['AI', 'Machine Learning', 'AutoML', 'AI Consulting', 'ML', 'Artificial Intelligence']}/>
        <IndexHero data={data.mainYaml.hero} clientLogos={data.mainYaml.clientListLogos}
                   sectionId="Home-page1__hero" updatePlatformRegistration={updatePlatformRegistration}/>
        <PlatformUses data={data.mainYaml.platformUses} sectionId="Home-page3__platform-uses"/>
        <SalesSection data={data.mainYaml.successSection} sectionId="Home-page4__sales-section-success"
                      has_square={true}/>
        <SuperhumanAppsSection data={data.mainYaml.superhumanAppsSection} sectionId="Home-page5__superhuman-apps"/>
        <SalesSection data={data.mainYaml.salesSection} sectionId="Home-page6__sales-section-services"
                      has_stripes={true} has_square={true}/>
        <AppFeatures data={data.mainYaml.appFeatures} sectionId="Home-page7__app-features"/>
        <SalesSection data={data.mainYaml.ourPhilosophy} sectionId="Home-page8__sales-section-philosophy"/>
        <ClientList data={data.mainYaml.clientList} sectionId="Home-page9__clients" has_text={true}
                    has_title={true}/>
        <GetInTouch sectionId="Home-page10__contact-us"/>
    </Layout>
  </>
)

const mapDispatchToProps = dispatch => ({
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})

export default connect(null, mapDispatchToProps)(IndexPage)

export const pageQuery = graphql`
  query {
    mainYaml {
      clientList {
        name
        logo
        figure
        fact
        time
        link
      }
      clientListLogos {
        name
        logo
      }
      hero {
        title
        titleBr
        subtitle
      }
      platformUses {
        title
        subtitle
        imgTitle
        whoTitle
        whoSubtitle
      }
      successSection {
        content
      }
      salesSection {
        content
      }
      superhumanAppsSection {
        icon
        title
        content
      }
      appFeatures {
        title
        subtitle
        image
      }
      ourPhilosophy {
        content
      }
    }
  }
`