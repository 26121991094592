import React from 'react'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import Image from 'components/image/image'
import './sales-section.sass';

const SalesSection = ({data, has_square, has_stripes}) => (
    <div className="SalesSection">
        <div className="container-fluid">
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        {has_square
            ? <React.Fragment>
                    <Image image_url="layout/bg-diamond.svg" alt="background diamond pattern big" className="SalesSection__background SalesSection__background--big"/>
                    <Image image_url="layout/bg-diamond--blue.svg" alt="background diamond pattern blue small" className="SalesSection__background SalesSection__background--small"/>
            </React.Fragment>
            : null
        }
        {has_stripes
            ? <React.Fragment>
                <Image image_url="layout/bg-platform-video-stripes.svg"
                    className="SalesSection__background SalesSection__background--stripes"
                    alt="background of hexagon shape" />
            </React.Fragment>
            : null
        }
    </div>
)

export default withSectionTracking(React.memo(SalesSection))