import React from 'react';
import { Link } from 'gatsby';
import './banner.sass';

const Banner = () => {
  return (
    <div className="banner">
      <div className="container-fluid">
        <Link to="/" className="banner-link active">AutoML & ML Ops</Link>
        <Link to="/ai/kortical-chat" className="banner-link">Kortical Chat</Link>
      </div>
    </div>
  );
};

export default Banner;